export default [
  {
    image: require('../image/cadeiraGamer.png'),
    text: 'Cadeira gamer',
    link: 'https://shopping.inter.co/busca?q=cadeira+gamer',
  },
  {
    image: require('../image/pcGamer.png'),
    text: 'PC gamer',
    link: 'https://shopping.inter.co/Games/dp/PC?dId=948&cId=965&s=RELEVANCY',
  },
  {
    image: require('../image/headSet.png'),
    text: 'Headset',
    link: 'https://shopping.inter.co/Audio/dp/Headphones?dId=35&cId=1978&s=RELEVANCY',
  },
  {
    image: require('../image/acessorios.png'),
    text: 'Acessórios',
    link: 'https://shopping.inter.co/Games/dp/Acessorios-Geek?dId=948&cId=949&s=RELEVANCY',
  },
  {
    image: require('../image/monitores.png'),
    text: 'Monitores',
    link: 'https://shopping.inter.co/busca?dId=948&cId=949&s=RELEVANCY&q=monitor+gamer',
  },
  {
    image: require('../image/HubECabos.png'),
    text: 'Hub e Cabos',
    link: 'https://shopping.inter.co/busca?dId=948&q=hub+gamer',
  },
  {
    image: require('../image/moveis.png'),
    text: 'Móveis',
    link: 'https://shopping.inter.co/Moveis/dp/Moveis-de-Escritorio?dId=1509&cId=1560&s=RELEVANCY',
  },
  {
    image: require('../image/controleGamer.png'),
    text: 'Controle gamer',
    link: 'https://shopping.inter.co/Games/dp/Controles-e-Acessorios?dId=948&cId=951&s=RELEVANCY',
  },
]
