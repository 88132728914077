import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import QrCodeGiftCard from '../../assets/image/qrcodeMundoGamer.png'
import Img from 'gatsby-image'
import { widths } from 'src/styles/breakpoints'

import pageQuery from '../../pageQuery'

import { Section, Button, Card } from './style'
import useWidth from 'src/hooks/window/useWidth'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import gameCards from '../../assets/data/_gameCards'

type CardsProps = {
  text?: string | null | undefined;
  description: string;
  image: string;
  link: string;
  name: string;
  alt?: string;
}

const GameGiftCards = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const width = useWidth(300)
  const oneLink = 'https://inter-co.onelink.me/Qyu7/qjqnvfrg'

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen} setIsModal={setIsOpen} qrCode={QrCodeGiftCard}
        instructions=''
        title='Aproveite todas as vantagens do <span class="text-orange--extra">Gift Card</span> no Super App '
        subtitle='<span class="text-center">Acesse o QR Code</span>'
      />
    </Modal>
  )

  return (
    <Section className='py-5'>
      {modal}
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 mb-3'>
            <Img
              fluid={data.gameGiftCards.fluid} alt='Homem e mulher sentados sorrindo enquanto jogam em games usando consoles'
            />
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-white fw-600 mb-3'>
              <span className='text-orange--extra'>Gift Card</span> de jogos pra você economizar
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-white mb-3'>
              Precisando fazer <span className='fw-700'>recarga de jogo</span>? É só garantir gift cards dos seus games favoritos! Você ainda economiza ganhando cashback na compra.
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-white mb-3'>
              Depois, basta informar o <span className='fw-700'>código de gift card</span> no respectivo app ou loja e aproveitar!
            </p>
            {
              width < widths.md ? (
                <a
                  href={oneLink}
                  className='link-mobile'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Escolher Gift Card',
                    section_name: 'Gif Card de jogos pra você economizar',
                    redirect_url: oneLink,
                  })
                  }}
                >

                  Escolher Gift Card
                </a>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      element_action: 'click button',
                      element_name: 'Escolher Gift Card',
                      section_name: 'Gif Card de jogos pra você economizar',
                    })
                  }
                  }
                >
                  Escolher Gift Card
                </Button>
              )
            }
            <div className='col-12 px-0 mt-md-3'>
              <DefaultCarousel autoPlay infinite sm={{ items: 1, partialVisibilityGutter: 150 }} md={{ items: 2 }} lg={{ items: 2, partialVisibilityGutter: 60 }} xl={{ items: 3 }} itemClass='px-2'>
                {
                  gameCards.map((item: CardsProps, index: number) => (
                    <Card
                      key={item.description}
                      href={item.link}
                      target='_blank'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: `dobra_04, p_${index + 1}`,
                          element_action: 'click button',
                          element_name: item.name,
                          section_name: 'Gif Card de jogos pra você economizar',
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <img src={item.image} className='mx-auto' alt={item.alt} />
                      <div>
                        <p className='fs-12 lh-15 text-grayscale--500 mb-0'>{item.text}</p>
                        <p className='fs-14 lh-17 fw-700 mb-0 description'>{item.description}</p>
                      </div>
                    </Card>
                  ))
                }
              </DefaultCarousel>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GameGiftCards
